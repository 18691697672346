import { createSlice } from "@reduxjs/toolkit";

const loanSlice = createSlice({
    name : 'loan' ,
    initialState : {
        docs : [] ,
        docDetails : null ,
        updateLoading : false , 
        showDeclinedReasonPopup : false ,
        page : 1 ,
        pages : 1 , 
        docsCount : 0 , 
    } , 
    reducers : {
        setStats (state , action) {
            const { docs , docsCount , page , pages } = action.payload;
            state.docs = docs;
            state.docsCount = docsCount;
            state.page = page;
            state.pages = pages;
        } ,
        setDocDetails (state , action) {
            state.docDetails = action.payload
        } ,
        updateDoc (state , action) {
            const index = state.docs.findIndex(i => i._id === action.payload._id);
            state.docs[index] = action.payload;
            state.docDetails = action.payload;
        } ,
        setUpdateLoading (state , action ) {
            state.updateLoading = action.payload
        } ,
        setShowDeclinedReasonPopup (state , action ) {
            state.showDeclinedReasonPopup = action.payload
        } ,
    }
});

export const { 
    setStats , setDocDetails , setUpdateLoading , updateDoc , setShowDeclinedReasonPopup
} = loanSlice.actions;

export default loanSlice.reducer;