import { createSlice } from "@reduxjs/toolkit";

const directPaymentSlice = createSlice({
    name : 'directPayment' ,
    initialState : {
        docs : [] ,
        page : 1 ,
        pages : 1 , 
        docsCount : 0 , 
    } , 
    reducers : {
        setStats (state , action) {
            const { docs , docsCount , page , pages } = action.payload;
            state.docs = docs;
            state.docsCount = docsCount;
            state.page = page;
            state.pages = pages;
        } ,
        updateDoc (state , action) {
            const index = state.docs.findIndex(i => i._id === action.payload._id);
            state.docs[index] = action.payload;
            state.docDetails = action.payload;
        }
    }
});

export const { 
    setStats ,
    updateDoc
} = directPaymentSlice.actions;

export default directPaymentSlice.reducer;