import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
    name : 'app' ,
    initialState : {
        showSidebar : false ,
        isCollapsed : false ,
    } ,
    reducers : {
        setShowSidebar (state , action) {
            state.showSidebar = action.payload;
        } ,
        setIsCollapsed (state , action) {
            state.isCollapsed = action.payload;
        }
    }
});

export const { setShowSidebar , setIsCollapsed } = appSlice.actions;
export default appSlice.reducer;