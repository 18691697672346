import { createSlice } from "@reduxjs/toolkit";

const donorSlice = createSlice({
    name : 'donor' ,
    initialState : {
        data : '' ,
        createLoading : false , 
        deleteLoading : false ,
    } , 
    reducers : {
        setData (state , action) {
            state.data = action.payload;
        } ,
        setCreateLoading (state , action ) {
            state.createLoading = action.payload
        } ,
        setDeleteLoading (state , action ) {
            state.deleteLoading = action.payload
        } ,
    }
});

export const { 
    setData , setCreateLoading  , setDeleteLoading  
} = donorSlice.actions;

export default donorSlice.reducer;