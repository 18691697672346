import { configureStore } from "@reduxjs/toolkit";
import appSlice from "./slices/appSlice";
import authSlice from './slices/authSlice';
import blogSlice from './slices/blogSlice';
import eventSlice from './slices/eventSlice';
import teamSlice from './slices/teamSlice';
import reviewSlice from './slices/reviewSlice';
import donorSlice from './slices/donorSlice';
import missionSlice from './slices/missionSlice';
import causeSlice from './slices/causeSlice';
import directPaymentSlice from './slices/directPaymentSlice';
import cardPaymentSlice from './slices/cardPaymentSlice';
import volunteerSlice from './slices/volunteerSlice';
import loanSlice from './slices/loanSlice';
import employeeSlice from './slices/employeeSlice';


const store = configureStore({
    reducer: {
        app : appSlice ,
        auth : authSlice ,
        blog : blogSlice ,
        event : eventSlice ,
        team : teamSlice ,
        review : reviewSlice ,
        donor : donorSlice ,
        mission : missionSlice , 
        cause : causeSlice ,
        directPayment : directPaymentSlice ,
        cardPayment : cardPaymentSlice ,
        volunteer : volunteerSlice ,
        loan : loanSlice ,
        employee : employeeSlice ,  
    }
});

export default store;