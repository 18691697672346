import { lazy } from "react";
import Loadable from "components/global/Loadable";
import { Navigate } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./ProtectedRoute";



// LAYOUTS
const MainLayout = Loadable(lazy(() => import('layouts/main')));
const BlankLayout = Loadable(lazy(() => import('layouts/blank')));
const AuthLayout = Loadable(lazy(() => import('layouts/auth')));

// APP
const Dashboard = Loadable(lazy(() => import('pages/app/dashboard')));

const Blogs = Loadable(lazy(() => import('pages/app/blogs')));
const AddNewBlog = Loadable(lazy(() => import('pages/app/blogs/AddNewBlog')));
const EditBlog = Loadable(lazy(() => import('pages/app/blogs/EditBlog')));

const Events = Loadable(lazy(() => import('pages/app/events')));
const AddEvent = Loadable(lazy(() => import('pages/app/events/AddEvent')));
const EditEvent = Loadable(lazy(() => import('pages/app/events/EditEvent')));

const Team = Loadable(lazy(() => import('pages/app/team')));
const AddTeamMember = Loadable(lazy(() => import('pages/app/team/AddTeamMember')));
const EditTeamMember = Loadable(lazy(() => import('pages/app/team/EditTeamMember')));

const Volunteers = Loadable(lazy(() => import('pages/app/volunteers')));
const LoanRequests = Loadable(lazy(() => import('pages/app/loanRequests')));
const LoanDetails = Loadable(lazy(() => import('pages/app/loanRequests/LoanDetails')));

const Employees = Loadable(lazy(() => import('pages/app/employees')));
const AddNewEmployee = Loadable(lazy(() => import('pages/app/employees/AddNewEmployee')));
const EmployeeWork = Loadable(lazy(() => import('pages/app/employees/EmployeeWork')));
const EditEmployee = Loadable(lazy(() => import('pages/app/employees/EditEmployee')));
const EmployeeTeam = Loadable(lazy(() => import('pages/app/employees/EmployeeTeam')));

const Reviews = Loadable(lazy(() => import('pages/app/reviews')));
const AddReview = Loadable(lazy(() => import('pages/app/reviews/AddReview')));
const EditReview = Loadable(lazy(() => import('pages/app/reviews/EditReview')));

const HomePage = Loadable(lazy(() => import('pages/app/pages/home')));
const Settings = Loadable(lazy(() => import('pages/app/settings')));

const Donors = Loadable(lazy(() => import('pages/app/pages/donors')));
const AddDonorSliderImages = Loadable(lazy(() => import('pages/app/pages/donors/AddDonorImages')));

const Missions = Loadable(lazy(() => import('pages/app/pages/missions')));
const AddNewMission = Loadable(lazy(() => import('pages/app/pages/missions/AddNewMission')));
const EditMission = Loadable(lazy(() => import('pages/app/pages/missions/EditMission')));

const Causes =  Loadable(lazy(() => import('pages/app/donate/causes')));
const AddCause =  Loadable(lazy(() => import('pages/app/donate/causes/AddCause')));
const EditCause =  Loadable(lazy(() => import('pages/app/donate/causes/EditCause')));

const Donations = Loadable(lazy(() => import('pages/app/donate/donations')));
const DirectPayments = Loadable(lazy(() => import('pages/app/donate/directPayments')));
const CardPayments = Loadable(lazy(() => import('pages/app/donate/cardPayments')));

// AUTH 
const Login = Loadable(lazy(() => import('pages/auth/Login')));
const ForgotPassword = Loadable(lazy(() => import('pages/auth/ForgotPassword')));
const VerifyOtp = Loadable(lazy(() => import('pages/auth/VerifyOtp')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));



// 404
const NotFound = Loadable(lazy(() => import('pages/404')));


const Router = [
    {
        path : '/' ,
        element : <BlankLayout  /> ,       
        children : [
            { path : '/' , exact : true , element : <Navigate to='/auth/login' /> } ,
            { path : '*' , element : <Navigate to='/page-not-found' /> }
        ]
    } ,
    {
        path : '/auth' ,
        element : <AuthLayout  /> ,       
        children : [
            { path : '/auth/login' , exact : true , element : <PublicRoute element={<Login />} /> } ,
            { path : '/auth/forgot-password' , exact : true , element : <PublicRoute element={<ForgotPassword />} /> } ,
            { path : '/auth/verify-otp' , exact : true , element : <PublicRoute element={<VerifyOtp />} /> } ,
            { path : '/auth/reset-password' , exact : true , element : <PublicRoute element={<ResetPassword />} /> } ,
            { path : '*' , element : <Navigate to='/page-not-found' /> }
        ]
    } ,
    {
        path : '/app' ,
        element : <MainLayout /> ,
        children : [
            { 
                path : '/app' , 
                exact : true , 
                element : <ProtectedRoute element={<Dashboard />} />
            } ,
            { 
                path : '/app/blogs' , 
                exact : true , 
                element : <ProtectedRoute element={<Blogs />} /> 
            } ,
            { 
                path : '/app/blogs/add' , 
                exact : true , 
                element : <ProtectedRoute element={<AddNewBlog />} /> 
            } ,
            { 
                path : '/app/blogs/edit/:slug' ,
                exact : true , 
                element : <ProtectedRoute element={<EditBlog />} /> 
            } ,
            { 
                path : '/app/events' , 
                exact : true , 
                element : <ProtectedRoute element={<Events />} /> 
            } ,
            { 
                path : '/app/events/add' , 
                exact : true , 
                element : <ProtectedRoute element={<AddEvent />} />
            } ,
            { 
                path : '/app/events/edit/:slug' , 
                exact : true , 
                element : <ProtectedRoute element={<EditEvent />} />
            } ,
            { 
                path : '/app/team' , 
                exact : true , 
                element : <ProtectedRoute element={<Team /> } /> 
            },
            { 
                path : '/app/team/add' , 
                exact : true , 
                element : <ProtectedRoute element={<AddTeamMember />} /> 
            } ,
            { 
                path : '/app/team/edit/:id' , 
                exact : true , 
                element : <ProtectedRoute element={<EditTeamMember />} /> 
            } ,
            
            { 
                path : '/app/volunteers/pending' , 
                exact : true , 
                element : <ProtectedRoute element={<Volunteers />} /> 
            } ,
            { 
                path : '/app/volunteers/approved' , 
                exact : true , 
                element : <ProtectedRoute element={<Volunteers />} /> 
            } ,
            { 
                path : '/app/volunteers/declined' , 
                exact : true , 
                element : <ProtectedRoute element={<Volunteers />} /> 
            } ,
            { 
                path : '/app/volunteers/expired' , 
                exact : true , 
                element : <ProtectedRoute element={<Volunteers />} /> 
            } ,
            { 
                path : '/app/loans/pending' , 
                exact : true , 
                element : <ProtectedRoute element={<LoanRequests />} /> 
            } ,
            { 
                path : '/app/loans/approved' , 
                exact : true , 
                element : <ProtectedRoute element={<LoanRequests />} /> 
            } ,
            { 
                path : '/app/loans/declined' , 
                exact : true , 
                element : <ProtectedRoute element={<LoanRequests />} /> 
            } ,
            { 
                path : '/app/loans/details/:id' , 
                exact : true , 
                element : <ProtectedRoute element={<LoanDetails />} /> 
            } ,
            { 
                path : '/app/employees' ,
                exact : true , 
                element : <ProtectedRoute element={<Employees /> } /> 
            },
            { 
                path : '/app/employees/add' , 
                exact : true , 
                element : <ProtectedRoute element={<AddNewEmployee />} /> 
            } ,
            { 
                path : '/app/employees/edit/:id' , 
                exact : true , 
                element : <ProtectedRoute element={<EditEmployee />} /> 
            } ,
            { 
                path : '/app/employees/team/:employeeId' , 
                exact : true , 
                element : <ProtectedRoute element={<EmployeeTeam />} /> 
            } ,
            { 
                path : '/app/employees/work/:employeeId' , 
                exact : true , 
                element : <ProtectedRoute element={<EmployeeWork />} /> 
            } ,
            { 
                path : '/app/reviews' ,
                exact : true , 
                element : <ProtectedRoute element={<Reviews /> } /> 
            },
            { 
                path : '/app/reviews/add' , 
                exact : true , 
                element : <ProtectedRoute element={<AddReview />} /> 
            } ,
            { 
                path : '/app/reviews/edit/:id' , 
                exact : true , 
                element : <ProtectedRoute element={<EditReview />} /> 
            } ,
            
            { 
                path : '/app/donate/donations' , 
                exact : true , 
                element : <ProtectedRoute element={<Donations /> } /> 
            },,
            { 
                path : '/app/donate/direct-payments' , 
                exact : true , 
                element : <ProtectedRoute element={<DirectPayments /> } /> 
            },
            { 
                path : '/app/donate/card-payments' , 
                exact : true , 
                element : <ProtectedRoute element={<CardPayments /> } /> 
            },
            { 
                path : '/app/donate/causes' , 
                exact : true , 
                element : <ProtectedRoute element={<Causes /> } /> 
            },
            { 
                path : '/app/donate/causes/add' , 
                exact : true , 
                element : <ProtectedRoute element={<AddCause /> } /> 
            },
            { 
                path : '/app/donate/causes/edit/:id' , 
                exact : true , 
                element : <ProtectedRoute element={<EditCause /> } /> 
            },

            { 
                path : '/app/pages/home' , 
                exact : true , 
                element : <ProtectedRoute element={<HomePage /> } />
            },
            { 
                path : '/app/pages/donors' , 
                exact : true , 
                element : <ProtectedRoute element={<Donors />} /> 
            } ,
            { 
                path : '/app/pages/donors/add-slider-images' , 
                exact : true , 
                element : <ProtectedRoute element={<AddDonorSliderImages />} /> 
            } ,
            { 
                path : '/app/pages/missions' , 
                exact : true , 
                element : <ProtectedRoute element={<Missions /> } /> 
            },
            { 
                path : '/app/pages/missions/add' , 
                exact : true , 
                element : <ProtectedRoute element={<AddNewMission />} /> 
            } ,
            { 
                path : '/app/pages/missions/edit/:id' , 
                exact : true , 
                element : <ProtectedRoute element={<EditMission />} /> 
            } ,
            { 
                path : '/app/settings' , 
                exact : true , 
                element : <ProtectedRoute element={<Settings />} /> 
            } ,
            { path : '*' , element : <Navigate to='/page-not-found' /> }
        ]
    } ,
    {
        path : '/page-not-found' ,
        element : <BlankLayout /> ,
        children : [
            { path : '/page-not-found' , element : <NotFound /> }
        ]
    }

]

export default Router;